import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthConfig, OAuthErrorEvent, OAuthInfoEvent, OAuthService, OAuthSuccessEvent } from 'angular-oauth2-oidc';
import * as jsonPatch from 'fast-json-patch';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';
import { calculateMutations, isNullOrUndefined } from 'sfx-commons';
import {
    IdentityProvider,
    LOGIN_LOGOUT_QUERY_PARAM,
    MAIN_ROUTES, OUTLET_NAMES
} from '../../constants';
import { ROUTE_ID } from '../../constants/api-routes';
import { IdentityProviderRefreshTokenConfigMap } from '../../constants/identity-provider-refresh-token-config-map';
import { IApplicationUser } from '../../core/models/IApplicationUser';
import { IRoleAndPermissionShortcuts } from '../../core/models/IRoleAndPermissionShortcuts';
import { HttpService } from '../../core/services/http.service';
import { LOCAL_STORAGE_KEY, LocalStorageService } from '../../core/services/local-storage.service';
import { LogoutImplicationsService } from '../../core/services/logout-implications.service';
import { RouterService } from '../../core/services/router.service';
import { SESSION_STORAGE_KEY, SessionStorageService } from '../../core/services/session-storage.service';
import { RouterUtils } from '../../core/utils/router.utils';
import { getDefaultSSOConfigs } from '../../core/utils/sso-config.utils';
import { DiagnosticsService } from '../../diagnostics/services/diagnostics.service';
import { MadTranslateService } from '../../mad-translate/services/mad-translate.service';
import { AuthorizationService } from './authorization.service';

export interface IAuthStatus {
    isAuthenticated: boolean;
    isAuthorized: boolean;
    tokenExpiration: number;
    isBackofficeUser: boolean;
    error: string;
    activeProvider: IdentityProvider;
}

const configs: { [key in IdentityProvider]?: AuthConfig } = getDefaultSSOConfigs();

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService implements IRoleAndPermissionShortcuts {
    public authStatus: Observable<IAuthStatus>;
    public readonly currentUser: Observable<IApplicationUser>;
    public hypoDossierToken: Observable<string>;

    private readonly EMPTY_AUTH_STATUS_OBJ: IAuthStatus = {
        isAuthenticated: false,
        isAuthorized: false,
        tokenExpiration: 0,
        isBackofficeUser: false,
        error: '',
        activeProvider: null
    };

    private activeIdentityProvider: IdentityProvider = IdentityProvider.SwissLife;
    private refetchTokenTimeout: ReturnType<typeof setTimeout>;
    private readonly authStatusSubject: BehaviorSubject<IAuthStatus>;
    private readonly currentUserSubject: BehaviorSubject<IApplicationUser>;
    private readonly hypoDossierTokenSubject: BehaviorSubject<string>;

    constructor(private readonly authorizationSvc: AuthorizationService,
                private readonly diagnosticsSvc: DiagnosticsService,
                private readonly httpSvc: HttpService,
                private readonly oAuthService: OAuthService,
                private readonly localStorageSvc: LocalStorageService,
                private readonly logoutImplicationsSvc: LogoutImplicationsService,
                private readonly madTranslateSvc: MadTranslateService,
                private readonly router: Router,
                private readonly routerSvc: RouterService,
                private readonly sessionStorageSvc: SessionStorageService,
                private readonly translate: TranslateService) {
        this.authStatusSubject = new BehaviorSubject<IAuthStatus>({
            ...this.EMPTY_AUTH_STATUS_OBJ
        });
        this.authStatus = this.authStatusSubject.asObservable();
        this.currentUserSubject = new BehaviorSubject<IApplicationUser>(undefined);
        this.currentUser = this.currentUserSubject.asObservable();

        const setProvider = this.localStorageSvc.get(LOCAL_STORAGE_KEY.SSO_ACTIVE_ID) as number;
        if (!isNullOrUndefined(setProvider)) {
            if (!!IdentityProvider[setProvider] && !!configs[setProvider]) {
                this.activeIdentityProvider = IdentityProvider[IdentityProvider[setProvider]] as IdentityProvider;
            } else {
                this.localStorageSvc.remove(LOCAL_STORAGE_KEY.SSO_ACTIVE_ID);
                void this.logout(false);
            }
        }

        this.hypoDossierTokenSubject = new BehaviorSubject<string>(null);
        this.hypoDossierToken = this.hypoDossierTokenSubject.asObservable();

        this.oAuthService.events
            .subscribe(async (event) => {
                if (window.location.pathname.includes('iss')) {
                    const pathName = window.location.pathname.split('&iss')[0];
                    window.history.pushState('', window.document.title, pathName);
                }

                switch (event.constructor) {
                    case OAuthSuccessEvent:
                        switch (event.type) {
                            case 'discovery_document_loaded':
                            case 'token_received':
                                this.cancelRefetchTokenTimeout();
                                this.updateAuthStatus();
                                break;
                            case 'token_refreshed':
                                this.cancelRefetchTokenTimeout();
                                if (!this.getAuthStatus().isAuthorized) {
                                    await this.loginToDfs();
                                }
                                this.updateAuthStatus();
                                break;
                            default:
                                break;
                        }
                        break;
                    case OAuthInfoEvent:
                        switch (event.type) {
                            case 'session_changed':
                                await this.loginToDfs();
                                this.updateAuthStatus();
                                break;
                            case 'session_error':
                                this.updateAuthStatus(null, event.type);
                                break;
                            case 'session_terminated':
                            case 'logout':
                                console.warn(event);
                                void this.logout();
                                break;
                            default:
                                break;
                        }
                        break;
                    case OAuthErrorEvent:
                        switch (event.type) {
                            case 'discovery_document_validation_error':
                            case 'code_error':
                            case 'discovery_document_load_error':
                            case 'jwks_load_error':
                            case 'token_validation_error':
                                this.updateAuthStatus(null, event.type);
                                break;
                            case 'token_error':
                            case 'token_refresh_error':
                                console.error(event);
                                if (this.doesIdentityProviderSupportRefreshToken(this.activeIdentityProvider)) {
                                    this.reattemptRefetchToken();
                                } else {
                                    this.updateAuthStatus(null, event.type);
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                }
            });

        // TODO: if and when login and logging-in urls have to be localized, come up with a better solution
        const currentLang = RouterUtils.getLanguageFromUri();
        configs[this.activeIdentityProvider].redirectUri = `${ window.location.origin }/${ currentLang.toLowerCase() }/${ MAIN_ROUTES.LOGGING_IN }`;

        if (this.activeIdentityProvider === IdentityProvider.DfsIdentityServer) {
            configs[this.activeIdentityProvider].customQueryParams = { language: currentLang.toLowerCase() };
        }

        this.configureAuthService(this.activeIdentityProvider, configs[this.activeIdentityProvider]);
    }

    public get isAdmin(): boolean {
        return this.authorizationSvc.isAdmin;
    }

    public get isSfxAdmin(): boolean {
        return this.authorizationSvc.isSfxAdmin;
    }

    public get isSupporter(): boolean {
        return this.authorizationSvc.isSupporter;
    }

    public get isUser(): boolean {
        return this.authorizationSvc.isUser;
    }

    public get isTrainee(): boolean {
        return this.authorizationSvc.isTrainee;
    }

    public getAuthStatus(): IAuthStatus {
        return this.authStatusSubject.getValue();
    }

    public hasValidAccessToken(): boolean {
        return this.oAuthService.hasValidAccessToken() &&
            +this.oAuthService.getAccessTokenExpiration() > Date.now();
    }

    public getBearerToken(): string {
        const token = this.getAccessToken();
        if (token) {
            return `Bearer ${ token }`;
        } else {
            return token;
        }
    }

    public getAccessToken(): string {
        return this.oAuthService.getAccessToken();
    }

    public async getCurrentUser(): Promise<IApplicationUser> {
        let user = this.getCurrentCachedUser();
        if (!user) {
            user = await this.httpSvc.get<IApplicationUser>(null, ROUTE_ID.CURRENT_USER);
            this.setCurrentUser(user);
            this.updateAuthStatus(user);
        }

        return user;
    }

    public getCurrentCachedUser(): IApplicationUser {
        return this.currentUserSubject.getValue();
    }

    public async obtainHypoDossierToken(projectId: number): Promise<string> {
        const { token } = await this.httpSvc
            .post<{ token: string }>(ROUTE_ID.OBTAIN_HYPO_DOSSIER_TOKEN, { projectId }, null, null, null, true);
        this.hypoDossierTokenSubject.next(token);

        return token;
    }

    public cancelObtainHypoDossierTokenRequest(): void {
        this.httpSvc.cancelRequest(ROUTE_ID.OBTAIN_HYPO_DOSSIER_TOKEN, 'POST');
    }

    public resetHypoDossierTokenIfExists(): void {
        if (!!this.hypoDossierTokenSubject.getValue()) {
            this.hypoDossierTokenSubject.next(null);
        }
    }

    public async updateCurrentUser(mutations: Array<jsonPatch.Operation>, formGroup: FormGroup): Promise<IApplicationUser> {
        const user = await this.httpSvc
            .jsonPatch<IApplicationUser>(formGroup, ROUTE_ID.CURRENT_USER, null, null, mutations);
        this.setCurrentUser(user);
        this.updateAuthStatus(user);

        return user;
    }

    public async login(identityProvider: IdentityProvider): Promise<void> {
        await this.logout(true);

        this.activeIdentityProvider = identityProvider;
        this.localStorageSvc.set(LOCAL_STORAGE_KEY.SSO_ACTIVE_ID, this.activeIdentityProvider);
        const translateRoute = this.routerSvc.translateRoute([MAIN_ROUTES.LOGGING_IN]);
        this.configureAuthService(this.activeIdentityProvider, {
            ...configs[this.activeIdentityProvider],
            redirectUri: `${ window.location.origin }/${ this.translate.currentLang }/${ translateRoute }`
        });

        await this.oAuthService.loadDiscoveryDocumentAndLogin();
    }

    public async loginToDfs(): Promise<IApplicationUser> {
        return await this.httpSvc
            .post<IApplicationUser>(ROUTE_ID.LOGIN);
        // this.setCurrentUser(user);
        // this.mapUserToAuthStatus(user);
    }

    public async logout(noRedirect = true, setReturnUrl = false, reason: string = null): Promise<void> {
        const authStatus = this.authStatusSubject.getValue();
        if (!authStatus.isAuthenticated) {
            return;
        }

        if (authStatus?.isAuthenticated) {
            void this.logoutFromDfs();
        }

        const config: AuthConfig = {
            ...configs[this.activeIdentityProvider],
            revocationEndpoint: this.oAuthService.revocationEndpoint
        };

        const shouldNotRedirectToSSOLogout = noRedirect && (this.activeIdentityProvider !== IdentityProvider.DfsIdentityServer ||
            this.activeIdentityProvider === IdentityProvider.DfsIdentityServer && !this.sessionStorageSvc.get(SESSION_STORAGE_KEY.SESSION_STATE) ||
            !this.sessionStorageSvc.get(SESSION_STORAGE_KEY.USER_LOGOUT));
        if (!shouldNotRedirectToSSOLogout) {
            const translatedLoginRoute = this.routerSvc.translateRoute([MAIN_ROUTES.LOGIN]);
            const queryParams = !!reason ? `?${ LOGIN_LOGOUT_QUERY_PARAM[reason] }=${ reason }` : '';
            config.postLogoutRedirectUri =
                `${ window.location.origin }/${ this.translate.currentLang }/${ translatedLoginRoute }${ queryParams }`;
            config.logoutUrl = this.oAuthService.logoutUrl;
        }
        this.oAuthService.stopAutomaticRefresh();
        this.oAuthService.configure(config);

        this.setCurrentUser(undefined);
        this.updateAuthStatus(null, '');
        this.localStorageSvc.remove(LOCAL_STORAGE_KEY.SSO_ACTIVE_ID);
        this.logoutImplicationsSvc.clearCache();

        if (this.doesIdentityProviderSupportRefreshToken(this.activeIdentityProvider) &&
            !!this.oAuthService.revocationEndpoint) {
            try {
                await this.oAuthService.revokeTokenAndLogout(shouldNotRedirectToSSOLogout);
            } catch (e) {
                console.error(e);
                this.oAuthService.logOut(false);
            }
        } else {
            this.oAuthService.logOut(shouldNotRedirectToSSOLogout);
        }

        if (shouldNotRedirectToSSOLogout) {
            await this.redirectToLogin(setReturnUrl, !!reason && !!LOGIN_LOGOUT_QUERY_PARAM[reason] ? { [LOGIN_LOGOUT_QUERY_PARAM[reason]]: reason } : null);
        }
    }

    public async markKey4TrainingAsCompleted(): Promise<void> {
        await this.httpSvc.post<void>(ROUTE_ID.MARK_KEY4_TRAINING_AS_COMPLETED);
    }

    public parseToken(jwtToken: string): JwtPayload {
        return jwtDecode(jwtToken);
    }

    public async prolongSession(): Promise<void> {
        this.sessionStorageSvc.set(SESSION_STORAGE_KEY.RETURN_URL_KEY, this.router.url);
        await this.login(this.activeIdentityProvider);
    }

    public configureAuthService(identityProvider: IdentityProvider, config: AuthConfig): void {
        this.oAuthService.configure(config);
        if (this.doesIdentityProviderSupportRefreshToken(identityProvider)) {
            this.oAuthService.setupAutomaticSilentRefresh();
        }
    }

    public doesIdentityProviderSupportRefreshToken(identityProvider: IdentityProvider): boolean {
        return !!IdentityProviderRefreshTokenConfigMap[identityProvider]?.isEnabled;
    }

    public async redirectToLogin(setReturnUrl = false, queryParams: Params): Promise<void> {
        const loginRoute = this.routerSvc.translateRoute([MAIN_ROUTES.LOGIN], null, true);
        const loggingInRoute = this.routerSvc.translateRoute([MAIN_ROUTES.LOGGING_IN], null, true);
        const currentUrl = this.router.url;
        if (!currentUrl.startsWith(loginRoute)) {
            await this.router.navigate([{
                outlets: {
                    primary: `${ this.madTranslateSvc.getSelectedLanguageFileSuffix() }/${ this.routerSvc.translateRoute([MAIN_ROUTES.LOGIN], null, false) }`,
                    [OUTLET_NAMES.NEWS_MODAL_OUTLET]: null,
                    [OUTLET_NAMES.SERVICE_CENTER_MODAL_OUTLET]: null,
                    [OUTLET_NAMES.SETTINGS_MODAL_OUTLET]: null
                }
            }], {
                queryParams: {
                    returnUrl: !setReturnUrl || currentUrl.length <= 1 || currentUrl.startsWith(loggingInRoute) ? null : currentUrl,
                    ...queryParams
                }
            });
        }
    }

    private async logoutFromDfs(): Promise<void> {
        try {
            await this.httpSvc.post<IApplicationUser>(ROUTE_ID.LOGOUT);
        } catch {
            // exception occurs when the token already expired, so there is no security threat
        }
    }

    private cancelRefetchTokenTimeout(): void {
        clearTimeout(this.refetchTokenTimeout);
    }

    private reattemptRefetchToken(): void {
        this.cancelRefetchTokenTimeout();
        this.refetchTokenTimeout = setTimeout(async () => {
            if (this.oAuthService.hasValidAccessToken() && this.oAuthService.getRefreshToken()) {
                await this.oAuthService.refreshToken();
            }
        }, 5000);
    }

    private updateAuthStatus(user?: IApplicationUser, errorKey?: string): void {
        let authStatus: IAuthStatus;
        const tokenExpiration = +this.oAuthService.getAccessTokenExpiration();
        if (!isNullOrUndefined(errorKey)) {
            authStatus = {
                ...this.EMPTY_AUTH_STATUS_OBJ,
                error: errorKey.length ? `auth.errors.${ errorKey }` : null
            };
        } else {
            const currentUser = user || this.getCurrentCachedUser();
            const isAuthenticated = this.hasValidAccessToken();
            const isAuthorized = isAuthenticated && !!currentUser;

            authStatus = {
                isAuthenticated,
                isAuthorized,
                tokenExpiration: isAuthenticated ? tokenExpiration : 0,
                activeProvider: isAuthenticated ? this.activeIdentityProvider : null,
                isBackofficeUser: isAuthorized ? currentUser.isInternalUser : false,
                error: null
            };
        }

        this.notifyAuthStatusChangeIfNecessary(authStatus);
    }

    private notifyAuthStatusChangeIfNecessary(authStatus: IAuthStatus): void {
        const cachedAuthStatus: IAuthStatus = this.authStatusSubject.getValue();
        const mutations = calculateMutations(authStatus, cachedAuthStatus);
        if (mutations.length) {
            this.diagnosticsSvc.log({
                key: authStatus.error ? 'updateAuthWithError' : 'updateAuthStatus',
                value: `authStatus: ${ JSON.stringify(authStatus) };${ authStatus.error ? '' :
                    ` tokenExpiration: ${ authStatus.tokenExpiration ? new Date(authStatus.tokenExpiration).toString() : authStatus.tokenExpiration }`}`
            });
            this.authStatusSubject.next(authStatus);
        }
    }

    private setCurrentUser(user: IApplicationUser): void {
        this.authorizationSvc.setRoleAndGlobalPermissionMaps(user);
        this.currentUserSubject.next(user);
    }
}
