import localesJson from '../assets/i18n/locales.json';
import { parseLanguages } from './common';

const serverUrl = 'api-test.swissfex.ch';
const apiProtocol = 'https://';
const wsProtocol = 'wss://';

const SL_SSO_CLIENT = '4533c74f15af40289bd3600a4eebb9cc';
const SL_SSO_SECRET = 'QK0FWWZSxTDm98pX9JzZFipcDsFn6L0OWoHpT3r6vIAptHvb6crx9PvHuXcDqMVV';
const SL_SSO_URL = 'https://id.uat.portals.swisslife.ch';

const AZURE_AD_CLIENT = '1e4615a0-b754-4996-9441-07b46d1b520d';
const AZURE_AD_TENANT = '87b9e7cf-956f-485c-a8f0-8d97d8c6d314';
const AZURE_AD_SCOPE = 'openid profile email api://1e4615a0-b754-4996-9441-07b46d1b520d/SwissFex.Read';

const DFS_IDENTITYSERVER_CLIENT = 'mad';
const DFS_IDENTITYSERVER_SECRET = '';
const DFS_IDENTITYSERVER_URL = 'https://auth-dev.swissfex.ch';

const HYPO_DOSSIER_BASE_URL = 'https://swissfex.test.hypodossier.ch/v1/static/';
const HYPO_DOSSIER_SERVICE_BASE_URL = 'https://dms.swissfex.test.hypodossier.ch/';

export const environment = {
    production: false,
    supportedLanguages: parseLanguages(localesJson),
    apiUrl: `${ apiProtocol }${ serverUrl }/api`,
    azureAdClient: AZURE_AD_CLIENT,
    azureAdUrl: `https://login.microsoftonline.com/${ AZURE_AD_TENANT }/v2.0`,
    azureAdScope: AZURE_AD_SCOPE,
    diagnosticLogKeyBase: 'SwissFEX.test@2024.8.1!',
    dfsIdentityServerClient: DFS_IDENTITYSERVER_CLIENT,
    dfsIdentityServerSecret: DFS_IDENTITYSERVER_SECRET,
    dfsIdentityServerUrl: DFS_IDENTITYSERVER_URL,
    isDfsIdentityServerAuthenticationEnabled: true,
    notificationsUrl: `${ wsProtocol }${ serverUrl }/hubs/live`,
    translationsUrl: 'https://translate.swissfex.ch/i18n/test/',
    translationsFallbackUrl: '/assets/i18n/',
    slSsoClient: SL_SSO_CLIENT,
    slSsoSecret: SL_SSO_SECRET,
    slSsoUrl: SL_SSO_URL,
    name: 'test',
    title: 'SFX Test',
    lenderIds: {
        ACREVIS: 11500,
        ASGA: 9100,
        BANK_CLER: 10200,
        BDL: 8700,
        BEKB: 8200,
        BPS: 9000,
        CREDIT_SUISSE_DE: 8000,
        CREDIT_SUISSE_FR: 8001,
        CREDIT_SUISSE_IT: 8002,
        DEMO: 1002,
        E_HYPO: 8600,
        GKB: 8300,
        GLKB: 9200,
        LUKB: 10100,
        MIGROS_BANK: 9800,
        PAX: 10400,
        PSW: 9300,
        RAIFFEISEN_BELALP_SIMPLON: 9700,
        RAIFFEISEN_BENKEN: 11900,
        RAIFFEISEN_DU_HAUT_LEMAN: 11300,
        RAIFFEISEN_DU_MONT_TENDRE: 11200,
        RAIFFEISEN_FRICK_METTAUERTAL: 11600,
        RAIFFEISEN_GENEVE_RHONE: 10600,
        RAIFFEISEN_GLARNERLAND: 10500,
        RAIFFEISEN_LOCARNO: 8500,
        RAIFFEISEN_LOSONE_PEDEMONTE_VALLEMAGGIA: 8400,
        RAIFFEISEN_LUZERN: 11700,
        RAIFFEISEN_MARTIGNY: 10900,
        RAIFFEISEN_MISCHABEL_MATTERHORN: 11800,
        RAIFFEISEN_MITTELRHEINTAL: 10700,
        RAIFFEISEN_NYON_LA_VALLEE: 10300,
        RAIFFEISEN_RAPPERSWIL_JONA: 10800,
        RAIFFEISEN_SANKT_GALLEN: 8080,
        RAIFFEISEN_SION: 11000,
        RAIFFEISEN_SURBTAL_WEHNTAL: 11100,
        RAIFFEISEN_SIERRE_REGION: 10000,
        RAIFFEISEN_WINTERTHUR: 9600,
        SOBA: 8800,
        SUVA: 8900,
        SWISS_LIFE: 30,
        SWISSCANTO_ANLAGESTIFTUNG: 9400,
        UBS: 137,
        UBS_KEY4: 9500,
        WIR_BANK: 9900,
        WKB: 8100,
        ZKB: 11400
    },
    documentIds: {
        LENDER_SPECIFIC_DOCUMENTS_CATEGORY_ID: 5,
        INBOX_CATEGORY_ID: 100
    },
    originsPerUserType: {
        Advisor: 'app-test.swissfex.ch',
        Broker: 'broker-test.swissfex.ch'
    },
    service: {
        email: 'service@swissfex.ch'
    },
    features: {
        clarity: {
            isEnabled: false,
            key: null
        },
        hypoDossier: {
            baseUrls: {
                component: HYPO_DOSSIER_BASE_URL,
                service: HYPO_DOSSIER_SERVICE_BASE_URL
            },
            scriptType: 'module'
        },
        userPilot: {
            isEnabled: true,
            key: 'NX-1883dd79',
            flows: {
                get_to_know_hd_doc_management: {
                    isEnabled: false,
                    key: 'T6L0J-bLm1'
                },
                leave_feedback: {
                    isEnabled: true,
                    key: 'Rs2Wc-bsGE'
                },
                supporter_info: {
                    isEnabled: true,
                    key: '5TMiamsLtH'
                }
            }
        }
    },
    googleMapsApiKey: 'AIzaSyAt4qvYGq-yAG9an-p4M7Qt5mEBjBoDD6s',
    inactivityTimeout: {
        monitor: 20 * 60 * 1000,
        logout: 10 * 60 * 1000
    },
    refreshTokenConfig: {
        sl: {
            isEnabled: false
        },
        sls: {
            isEnabled: false
        },
        azure: {
            isEnabled: true
        },
        dfs: {
            isEnabled: true
        }
    }
};
